const DEFAULT_ERRORS = { validation_errors: {} }

export default class ModelErrors {
  constructor() {
    this.errors = DEFAULT_ERRORS
  }

  save(errors) {
    if (errors.response.status !== 422) return

    this.errors = errors.response.data
  }

  all() {
    return this.errors.validation_errors
  }

  get(columnName) {
    if (this.errors.validation_errors[columnName]) {
      return this.errors.validation_errors[columnName].join(',')
    }
    return ''
  }

  clear(columnName) {
    if (columnName) {
      this.errors.validation_errors[columnName] = null // to trigger event

      delete this.errors.validation_errors[columnName]
      return
    }

    this.errors = DEFAULT_ERRORS
  }
}
